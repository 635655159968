import React, { useEffect } from "react";
import { setLayout } from "../../shell/redux/shellActions";
import { useDispatch } from "react-redux";
import { usePortalLink } from "./shellHelper";
import { ExternalLinkEnum } from "../../api/Client";
import ExternalLinkView from "../externalLink/ExternalLinkView";

interface IProps {
    title: (string | undefined)[];
    titleReactNode?: React.ReactNode | undefined;
    showGuidance: boolean;
    navbar?: React.ReactNode | undefined;
    children: React.ReactNode;
}

export default function PageLayout(props: IProps): JSX.Element {
    const dispatch = useDispatch();

    const portalGuidanceLink = usePortalLink(ExternalLinkEnum.NeatsNopimsPublicPortalGuidance);

    let titleDisplay = props.title.filter((t) => t !== undefined && t !== "").join(" - ");
    if (titleDisplay.length > 100) titleDisplay = titleDisplay.substring(0, 97) + "...";

    useEffect(() => {
        // titleDisplay: pass through the browser tab header
        // includePadding: true
        // showPageHeader: false - we provide a page header solution here
        dispatch(setLayout(titleDisplay, false, true));
    }, [titleDisplay]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="row">
                <div className="col">{props.titleReactNode ? props.titleReactNode : <h1>{titleDisplay}</h1>}</div>
                {props.showGuidance && portalGuidanceLink && (
                    <div className="col-auto">
                        <div className="mt-2 float-right">
                            <ExternalLinkView href={portalGuidanceLink}>Guidance</ExternalLinkView>
                        </div>
                    </div>
                )}
            </div>

            {props.navbar}
            {props.children}
        </div>
    );
}
