import * as Client from "../../api/Client";

export enum shellActions {
    setBusyRequest = "SHELL.SET_BUSY_REQUEST",
    clearBusyRequest = "SHELL.CLEAR_BUSY_REQUEST",
    setDirtyRequest = "SHELL.SET_DIRTY_REQUEST",
    clearDirtyRequest = "SHELL.CLEAR_DIRTY_REQUEST",
    setLayout = "SHELL.SET_LAYOUT",
    refreshStaticConfigurationRequest = "SHELL.REFRESH_STATIC_CONFIGURATION_REQUEST",
    refreshStaticConfigurationResponse = "SHELL.REFRESH_STATIC_CONFIGURATION_RESPONSE",
    userTokenLoaded = "SHELL.USER_TOKEN_LOADED",
}

export interface IShellActions {
    type: shellActions;
    busyCount?: number;
    pageTitle?: string;
    showPageHeader?: boolean;
    includePadding?: boolean;
    isStaticConfigurationLoaded?: boolean;
    featureSwitchesResponse?: Client.GetPublicShellFeatureSwitchesResponseDto | undefined;
    portalLinksResponse?: Client.GetPublicShellPortalLinksResponseDto | undefined;
    userToken?: string | undefined;
}

export function setBusyAction() {
    return {
        type: shellActions.setBusyRequest,
    };
}

export function clearBusyAction() {
    return {
        type: shellActions.clearBusyRequest,
    };
}

export function setDirtyAction() {
    return {
        type: shellActions.setDirtyRequest,
    };
}

export function clearDirtyAction() {
    return {
        type: shellActions.clearDirtyRequest,
    };
}

export function setLayout(pageTitle: string, showPageHeader: boolean = true, includePadding: boolean = true): IShellActions {
    return {
        type: shellActions.setLayout,
        pageTitle: pageTitle,
        showPageHeader: showPageHeader,
        includePadding: includePadding,
    };
}

export function refreshStaticConfigurationRequestAction(): IShellActions {
    return {
        type: shellActions.refreshStaticConfigurationRequest,
    };
}

export function refreshStaticConfigurationResponseAction(
    featureSwitchResponse: Client.GetShellFeatureSwitchesResponseDto,
    portalLinksResponse: Client.GetPublicShellPortalLinksResponseDto
): IShellActions {
    return {
        type: shellActions.refreshStaticConfigurationResponse,
        isStaticConfigurationLoaded: true,
        featureSwitchesResponse: featureSwitchResponse,
        portalLinksResponse: portalLinksResponse,
    };
}

export function userTokenLoaded(userToken: string): IShellActions {
    return {
        type: shellActions.userTokenLoaded,
        userToken: userToken,
    };
}
