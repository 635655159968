// framework
import { combineReducers } from "redux";
import { shellReducer } from "../shell/redux/shellReducers";
// user components
import { titleSearchReducer } from "../opggs/title/titleSearch/redux/titleSearchReducers";
import { titleDetailsReducer } from "../opggs/title/titleDetails/redux/titleDetailsReducers";
import { opggsApplicationTrackingReducer } from "../opggs/application/opggsApplicationTracking/redux/opggsApplicationTrackingReducers";
import { opggsApplicationTrackingDetailsReducer } from "../opggs/application/opggsApplicationTrackingDetails/redux/opggsApplicationTrackingDetailsReducers";
import { openTitleReducer } from "../open/title/redux/openTitleReducers";
import { licenceSearchReducer } from "../oei/licence/search/redux/licenceSearchReducers";
import { licenceDetailsReducer } from "../oei/licence/details/redux/licenceDetailsReducers";
import { openLicenceReducer } from "../open/licence/redux/openLicenceReducers";
import { oeiApplicationTrackingReducer } from "../oei/application/oeiApplicationTracking/redux/oeiApplicationTrackingReducers";
import { oeiApplicationTrackingDetailsReducer } from "../oei/application/oeiApplicationTrackingDetails/redux/oeiApplicationTrackingDetailsReducers";
import { reducer as nopimsBasketReducer } from "../nopims/basket/redux/reducers";

// root reducer
// - combines all of the reducers and gives them mutually exclusive scopes
const rootReducer = combineReducers({
    // infrastructure
    shell: shellReducer,
    // areas
    titleSearch: titleSearchReducer,
    titleDetails: titleDetailsReducer,
    applicationTracking: opggsApplicationTrackingReducer,
    applicationTrackingDetails: opggsApplicationTrackingDetailsReducer,
    openTitle: openTitleReducer,
    licenceSearch: licenceSearchReducer,
    licenceDetails: licenceDetailsReducer,
    openLicence: openLicenceReducer,
    oeiApplicationTracking: oeiApplicationTrackingReducer,
    oeiApplicationTrackingDetails: oeiApplicationTrackingDetailsReducer,
    nopimsBasket: nopimsBasketReducer,
});

export default rootReducer;
